import React from "react"
import classnames from "classnames"
import { Link } from "gatsby"

export default function Button({
  children,
  className,
  buttonStyle,
  to,
  expand,
}) {
  //   const styles = {
  //     primary: "py-2 px-6 bg-primary text-white",
  //     secondary: "py-2 px-6 bg-secondary text-primary",
  //     primaryInverse: "py-2 px-6 border border-primary text-primary",
  //     secondaryInverse: "py-2 px-6 border border-secondary text-primary",
  //   }
  return (
    <Link to={to} className={className}>
      <button
        className={classnames(
          "py-2 px-6 text-sm box-border",
          expand ? "w-full" : "",
          {
            "bg-primary text-white": buttonStyle === "primary",
            "bg-secondary border-2 border-secondary text-primary":
              buttonStyle === "secondary",
            "bg-secondary border-2 border-primary text-primary":
              buttonStyle === "secondaryOutlined",
            "border border-primary text-primary":
              buttonStyle === "primaryInverse",
            "border border-secondary text-primary":
              buttonStyle === "secondaryInverse",
            "border-2 border-tertiary text-primary bg-white":
              buttonStyle === "tertiaryInverse",
          }
        )}
      >
        {children}
      </button>
    </Link>
  )
}

Button.defaultProps = {
  children: "There is no set button text.",
}
