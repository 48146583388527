import React from "react"

export default function RibbonText({ text, highlighted, justify, className }) {
  return (
    <div className={className}>
      <div className="flex flex-row items-center space-x-2 whitespace-nowrap">
        {justify === "left" && <div className="bg-primary w-2 h-6" />}
        <p className="subtitle text-primary">
          {text} <span className="text-tertiary">{highlighted}</span>
        </p>
        {justify === "right" && <div className="bg-primary w-2 h-6" />}
      </div>
    </div>
  )
}
