import React from "react"

export default function Hero({ content, background, overlay }) {
  return (
    <section className="section h-95vh sm:h-[90vh] md:h-95vh min-h-0">
      <div className="wrapper pt-0 h-95vh relative text-white">
        <div className={"relative z-10 h-full bg-primary " + overlay}>
          <div className="h-full flex flex-col px-4 py-8 md:px-8 lg:p-16">
            {content}
          </div>
        </div>
        <div className="absolute inset-0 p-4 pt-0">
          <div className="relative w-full h-full">{background}</div>
        </div>
      </div>
    </section>
  )
}

Hero.defaultProps = {
  overlay: "bg-opacity-50",
}
