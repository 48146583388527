import React from "react"

import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Hero from "../../components/Hero"
import RibbonText from "../../components/ui/RibbonText"
import ContactBanner from "../../components/ContactBanner"
import Seo from "../../components/Seo"
import { Link } from "gatsby"
import { navigate } from "@gatsbyjs/reach-router"

export default function CompareTheBuilders() {
  return (
    // <Layout>
    //   <Seo title="Compare the Builders" />
    //   <Hero
    //     content={
    //       <div className="flex flex-col h-full justify-between md:justify-start">
    //         <h1 className="mb-12 max-w-md !normal-case">
    //           Compare the Builders
    //         </h1>
    //         <p className="w-2/3 sm:w-1/2 md:w-48">
    //           This in depth builder comparison allows you to make informed
    //           decisions about your next project.
    //         </p>
    //       </div>
    //     }
    //     background={
    //       <StaticImage
    //         src="../../images/planning.png"
    //         className="w-full h-full"
    //         quality={100}
    //         alt="An individual drawing on architectural planning paper."
    //       />
    //     }
    //     overlay="bg-opacity-50"
    //   />
    //   <section className="wrapper py-8 sm:py-16">
    //     <div className="container flex flex-col space-y-8 sm:space-y-16">
    //       <div className="flex flex-col-reverse sm:flex-row space-y-reverse space-y-4 sm:space-x-16">
    //         <div className="sm:w-11/12 space-y-4">
    //           <div className="space-y-2">
    //             <p>
    //               Below is a sample of Builder Finders’ tender analysis. Taking
    //               complete control of the tendering process, we present each builder's quote in an easy to compare format. This easy to understand
    //               comparison puts all the information at your fingertips,
    //               allowing you to make an informed decision about which builder
    //               is the right fit for you.
    //             </p>
    //           </div>
    //           <RibbonText text="In your" highlighted="control" justify="left" />
    //         </div>
    //         <div className="text-right flex flex-col items-end">
    //           <h2 className="w-2/3 sm:w-auto">
    //             It’s something no house builder can offer{" "}
    //             <span className="text-tertiary">you</span>.
    //           </h2>
    //           <h2 className="w-2/3 sm:w-4/5">
    //             Talk to <span className="text-tertiary">us</span> before you
    //             talk to a builder.
    //           </h2>
    //         </div>
    //       </div>
    //       <div className="w-full flex flex-col items-center">
    //         <StaticImage
    //           src="../../images/compare-the-builders-table.png"
    //           alt="Builder comparison table."
    //           className="max-w-3xl"
    //         />
    //       </div>
    //     </div>
    //   </section>
    //   <ContactBanner />
    // </Layout>
    <Layout>
      <section className="section wrapper container min-h-[60vh] flex flex-col justify-center items-center">
        <div className="flex flex-col w-80 items-center justify-center h-full">
          <h2>404 Error</h2>
          <p className="my-4 text-center">
            This page was not found. Please check the url or{" "}
            <Link
              to="/find-a-builder/contact-us"
              className="underline hover:no-underline"
            >
              contact us directly
            </Link>
          </p>
          <button
            className="bg-primary text-white py-2 px-6 text-sm"
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
        </div>
      </section>
    </Layout>
  )
}
