import React from "react"
import Button from "./ui/Button"

export default function ContactBanner({ className }) {
  return (
    <div className={className}>
      <div className="flex flex-col justify-center items-center py-16 px-16 space-y-8">
        <h2>
          Excited to start <span className="text-tertiary">building?</span>
        </h2>
        <div className="subtitle text-center space-y-4">
          <p>What are you waiting for?</p>
          <p>
            Contact us now and experience the ease of building your dream home!
          </p>
        </div>
        <Button
          className="mt-4"
          buttonStyle="primary"
          to="/find-a-builder/contact-us"
        >
          Contact Us
        </Button>
      </div>
    </div>
  )
}
